
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);

const userTz = dayjs.tz.guess();

export default {
  formatTime: function(epochSec) {
    return dayjs.unix(epochSec).tz(userTz).format('YYYY-MM-DD HH:mm:ss');
  },
  /**
   * 
   * @param diffDay. 0 means today. -1 means yesterday. 1 means tomorrow... 
   */
  getUnixStartOfDay: function(diffDay) {
    return dayjs().tz(userTz).startOf('day').unix() + diffDay * 86400;
  },
  getUnixEndOfDay: function(diffDay) {
    return dayjs().tz(userTz).endOf('day').unix() + diffDay * 86400;
  },
  getUnixTime: function(diffDay) {
    return dayjs().unix() + diffDay * 86400;
  },
  getUnixStartOfDay2: function(strDate, format) {
    return dayjs(strDate, format).tz(userTz).startOf('day').unix();
  },
  getUnixEndOfDay2: function(strDate, format) {
    return dayjs(strDate, format).tz(userTz).endOf('day').unix();
  }
}

