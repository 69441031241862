<template>
  <div>
    <CRow>
      <CCol sm="12" :md="{size: 8, offset: 2}">
        <CCard>
          <CCardHeader>
            <CIcon name="cil-justify-center"/><strong> Inbox </strong>
            <div
              class="spinner-border spinner-border-sm login-spinner"
              role="status"
              :hidden="!loading"
              >
              <span class="sr-only">Loading...</span>
            </div>
            <div class="card-header-actions">
              <CDropdown
                color="primary"
                toggler-text="Filter" 
                size="sm"
                placement="bottom-end"
              >
                <CDropdownItem
                  v-for="item in filters"
                  :key="item.value"
                  :active="item.value === type"
                  @click="updateType(item.value)"
                  >
                  {{ item.name }}
                </CDropdownItem>
              </CDropdown>
            </div>
          </CCardHeader>
          <CCardBody>
            <CListGroup>
              <CListGroupItem
                href="#"
                @click="selectThread(item)"
                class="flex-column align-items-start"
                v-for="item in messages"
                :key="item._id"
              >
                <div class="d-flex w-100 justify-content-between" :class="!item.read ? 'unread-msg' : false">
                  <h5 class="mb-1">{{ item.user_phone_number }}</h5>
                  <small>{{ item.created_time }}</small>
                </div>
                <small :class="!item.read ? 'unread-msg' : false">{{ item.message }}</small>
              </CListGroupItem>
            </CListGroup>

            <br>
            <CPagination
              :activePage.sync="currPage"
              :pages.sync="totalPage"
              align="center"
              @update:activePage="pageChanged"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import AxiosBase from '../../api/AxiosBase'
import TimeUtils from '../../common/TimeUtils'

const filters = [
  { value: "all", name: "All" },
  { value: "unread", name: "Unread" }
];

export default {
  name: 'Inbox',
  components: {
  },
  created() {
    this.loadMessage(0, this.perPage, this.type);
  },
  data() {
    return {
      filters,

      totalPage: 0,
      currPage: 1,
      perPage: 10,
      type: "all",
      loading: false,
      messages: []
    }
  },
  methods: {
    loadMessage(from, size, type) {
      var _this = this;
      _this.loading = true;
      AxiosBase.get("/conversation/inbox", {
        params: {
          from: from,
          size: size,
          type: type
        }
      })
      .catch(function(error) {
        console.log(error);
        _this.totalPage = 0;
        _this.loading = false;
      })
      .then(function(res) {
        let resCode = res?.data?.code;
        let resMsgs = res?.data?.value?.items;
        let total = res?.data?.value?.total;

        if (resCode != null && resCode >= 0 && resMsgs && total >= 0) {
          _this.messages.length = 0;
          for (let msgItem of resMsgs) {
            msgItem.created_time = TimeUtils.formatTime(msgItem.created_time / 1000);
            _this.messages.push(msgItem);
          }

          _this.totalPage = parseInt(total / size) + 1;
        } else {
          _this.totalPage = 0;
        }
        _this.loading = false;
      });
    },
    pageChanged(pageNumber, reduced) {
      this.loadMessage(this.perPage * (pageNumber - 1), this.perPage, this.type);
    },
    selectThread(item) {
      this.$router.push(`/conversation/detail/${item.user_phone_number}`);
    },
    updateType(value) {
      this.type = value;
      this.loadMessage(0, this.perPage, this.type);
    }
  },
}
</script>

<style scoped>
.unread-msg {
  color: #000;
}
</style>